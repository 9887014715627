import React from 'react';
import createReactClass from 'create-react-class';
import _ from 'lodash';
import {string, object, func, bool} from 'prop-types';
import {Captcha as CoreCaptcha, CaptchaLang} from 'wix-ui-core/captcha';
import {st, classes, $skin} from './Captcha.st.css';
import {mixins, santaTypesDefinitions, utils, constants} from '@wix/santa-components';
import {getGoogleLangCode} from '../../utils/localeUtils';
import {getDataAttributes} from '../../utils/attributesUtils';

const {ACTION_TYPES} = constants.SITE;
const wixCaptchaPublicKey = '6LejwKAUAAAAACs5B-wV9ndjxrE49x3lGztPznl_';

export class Captcha extends React.Component {
  resetCaptcha() {
    this.node.resetCaptcha();
  }

  render() {
    const {
      required,
      onVerify,
      onExpire,
      onReset,
      onError,
      captchaKey,
      size,
      lang,
      theme,
      captchaType,
    } = this.props;
    const dataAttr = getDataAttributes(this.props);
    const publicKey = (captchaKey) ? captchaKey : wixCaptchaPublicKey;
    return (
      <CoreCaptcha
        {...dataAttr}
        ref={node => (this.node = node)}
        required={required}
        sitekey={publicKey}
        onVerify={onVerify}
        onExpire={onExpire}
        onReset={onReset}
        onError={onError}
        size={size}
        lang={Captcha.translateLangCodeToCaptchaLang(lang)}
        theme={theme}
        captchaType={captchaType}
        className={st(classes.root, this.props.className)}
      />
    );
  }

  static translateLangCodeToCaptchaLang(siteLangCode) {
    const captchaValueDict = Object.values(CaptchaLang).reduce((dict, code) => {
      dict[code] = code;
      return dict;
    }, {});
    return getGoogleLangCode(siteLangCode, {googleLangs: captchaValueDict, specialCasesOverrides: {he: CaptchaLang.Hebrew, zh: CaptchaLang.ChineseTraditional}});
  }
}

Captcha.displayName = 'Captcha';

Captcha.propTypes = {
  onVerify: func,
  onExpire: func,
  onReset: func,
  onError: func,
  captchaKey: string,
  size: string,
  captchaType: string,
  theme: string,
  lang: string,
  componentViewMode: string,
  required: bool,
  style: object,
  className: string,
};

const {skinBasedComp, runTimeCompData, inputFocusMixin} = mixins;
const getComponentSkins = () => ({'wixui.skins.Captcha': $skin});

/**
 * @class components.captcha
 * @extends {core.skinBasedComp}
 */

export const santaCaptcha = createReactClass({
  displayName: 'Captcha',

  mixins: [
    skinBasedComp(getComponentSkins()),
    runTimeCompData,
    inputFocusMixin,
  ],

  propTypes: {
    structure: santaTypesDefinitions.Component.structure.isRequired,
    shouldResetComponent: santaTypesDefinitions.RenderFlags.shouldResetComponent,
    compProp: santaTypesDefinitions.Component.compProp.isRequired,
    compData: santaTypesDefinitions.Component.compData.isRequired,
    componentViewMode: santaTypesDefinitions.RenderFlags.componentViewMode,
    styleId: santaTypesDefinitions.Component.styleId,
    style: santaTypesDefinitions.Component.style,
    id: santaTypesDefinitions.Component.id,
    currentLanguage: santaTypesDefinitions.RendererModel.multilingual.currentLanguage,
    isMLEnabled: santaTypesDefinitions.RendererModel.multilingual.isEnabled,
    languageCode: santaTypesDefinitions.RendererModel.languageCode
  },

  statics: {
    behaviors: {
      resetCaptcha: {methodName: 'resetCaptcha'},
    },
    getComponentSkins,
  },

  resetCaptcha() {
    this.captchaRef.node.resetCaptcha();
  },

  /**
   * in case where we preview the site and go back to the editor we want to reset the captcha.
   * @param prevProps
   */
  componentDidUpdate(prevProps) {
    if (this.props.shouldResetComponent && prevProps.shouldResetComponent !== this.props.shouldResetComponent) {
      this.resetCaptcha();
    }
  },

  detectCaptchaLanguage() {
    const {isMLEnabled, currentLanguage, languageCode} = this.props;
    if (isMLEnabled && (currentLanguage.code || currentLanguage.languageCode)) {
      return currentLanguage.code ? currentLanguage.code : currentLanguage.languageCode;
    } else if (languageCode) {
      return languageCode;
    } else {
      return CaptchaLang.EnglishUS.toString();
    }
  },

  getSkinProperties() {
    const viewerHandler = this.props.compProp.viewerHandler;
    const captchaProps = _.assign({}, this.props.compData, this.props.compProp, {
      className: this.props.styleId,
      style: this.props.style,
      id: this.props.id,
      lang: this.detectCaptchaLanguage(),
      required: true,
      onVerify: token => {
        if (viewerHandler) {
          viewerHandler({token, reset: () => this.resetCaptcha()});
        }
        this.updateData({token});
        this.handleAction(ACTION_TYPES.ON_VERIFY);
      },
      onExpire: () => {
        if (viewerHandler) {
          viewerHandler({token: undefined, reset: () => this.resetCaptcha()}, ACTION_TYPES.ON_TIMEOUT);
        }
        this.updateData({token: undefined});
        this.handleAction(ACTION_TYPES.ON_TIMEOUT);
      },
      onError: () => {
        if (viewerHandler) {
          viewerHandler({token: undefined, reset: () => this.resetCaptcha()}, ACTION_TYPES.ON_ERROR);
        }
        this.handleAction(ACTION_TYPES.ON_ERROR);
      },
      onReset: () => {
        if (viewerHandler) {
          viewerHandler({token: undefined, reset: () => this.resetCaptcha()});
        }
        this.updateData({token: undefined});
      },
      ref: captchaRef => {
        this.captchaRef = captchaRef;
      },
    });
    return {
      '': {
        children: [utils.createReactElement(Captcha, captchaProps)],
      },
    };
  },
});
