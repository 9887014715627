// List sourced from - https://developers.google.com/maps/faq#languagesupport
const GOOGLE_DEFAULT_LANGUAGE_CODES = {
  af: 'af',
  ja: 'ja',
  sq: 'sq',
  kn: 'kn',
  am: 'am',
  kk: 'kk',
  ar: 'ar',
  km: 'km',
  hy: 'hy',
  ko: 'ko',
  az: 'az',
  ky: 'ky',
  eu: 'eu',
  lo: 'lo',
  be: 'be',
  lv: 'lv',
  bn: 'bn',
  lt: 'lt',
  bs: 'bs',
  mk: 'mk',
  bg: 'bg',
  ms: 'ms',
  my: 'my',
  ml: 'ml',
  ca: 'ca',
  mr: 'mr',
  zh: 'zh',
  mn: 'mn',
  ne: 'ne',
  no: 'no',
  pl: 'pl',
  hr: 'hr',
  pt: 'pt',
  cs: 'cs',
  da: 'da',
  nl: 'nl',
  pa: 'pa',
  en: 'en',
  ro: 'ro',
  ru: 'ru',
  sr: 'sr',
  et: 'et',
  si: 'si',
  fa: 'fa',
  sk: 'sk',
  fi: 'fi',
  sl: 'sl',
  es: 'es',
  fr: 'fr',
  sw: 'sw',
  gl: 'gl',
  sv: 'sv',
  ka: 'ka',
  ta: 'ta',
  de: 'de',
  te: 'te',
  el: 'el',
  th: 'th',
  gu: 'gu',
  tr: 'tr',
  iw: 'iw',
  uk: 'uk',
  hi: 'hi',
  ur: 'ur',
  hu: 'hu',
  uz: 'uz',
  is: 'is',
  vi: 'vi',
  id: 'id',
  zu: 'zu',
  it: 'it',
  fil: 'fil',
  'es-419': 'es-419',
  'fr-CA': 'fr-CA',
  'zh-CN': 'zh-CN',
  'zh-HK': 'zh-HK',
  'zh-TW': 'zh-TW',
  'pt-BR': 'pt-BR',
  'pt-PT': 'pt-PT',
  'en-AU': 'en-AU',
  'en-GB': 'en-GB',
};

const WIX_TO_GOOGLE_DEFAULT_SPECIAL_CASES = {
  // iw is hebrew's code in Google
  he: GOOGLE_DEFAULT_LANGUAGE_CODES.iw
};

const DEFAULT_FALLBACK_LANG = GOOGLE_DEFAULT_LANGUAGE_CODES.en;

const toGoogleRegionalFormat = (baseCode, regionalCode) => {
  return `${baseCode}-${regionalCode.toUpperCase()}`;
};

export const getGoogleLangCode = (wixLangCode, {googleLangs, specialCasesOverrides} = {}) => {
  const langsDictionary = googleLangs || GOOGLE_DEFAULT_LANGUAGE_CODES;
  const specialCases = specialCasesOverrides || WIX_TO_GOOGLE_DEFAULT_SPECIAL_CASES;
  const ALL_TRANSLATIONS = {...langsDictionary, ...specialCases};
  if (ALL_TRANSLATIONS[wixLangCode]) {
    return ALL_TRANSLATIONS[wixLangCode];
  } else if (wixLangCode && wixLangCode.includes && wixLangCode.includes('-')) {
    const [baseCode, regionalCode] = wixLangCode.split('-');
    return ALL_TRANSLATIONS[toGoogleRegionalFormat(baseCode, regionalCode)] || ALL_TRANSLATIONS[baseCode] || DEFAULT_FALLBACK_LANG;
  } else {
    return DEFAULT_FALLBACK_LANG;
  }
};

export const COUNTRY_CODES = [
  'ABW',
  'AFG',
  'AGO',
  'AIA',
  'ALA',
  'ALB',
  'AND',
  'ANT',
  'ARE',
  'ARG',
  'ARM',
  'ASM',
  'ATA',
  'ATF',
  'ATG',
  'AUS',
  'AUT',
  'AZE',
  'BDI',
  'BEL',
  'BEN',
  'BFA',
  'BGD',
  'BGR',
  'BHR',
  'BHS',
  'BIH',
  'BLR',
  'BLZ',
  'BMU',
  'BOL',
  'BRA',
  'BRB',
  'BRN',
  'BTN',
  'BVT',
  'BWA',
  'CAF',
  'CAN',
  'CCK',
  'CHE',
  'CHL',
  'CHN',
  'CIV',
  'CMR',
  'COD',
  'COG',
  'COK',
  'COL',
  'COM',
  'CPV',
  'CRI',
  'CXR',
  'CYM',
  'CYP',
  'CZE',
  'DEU',
  'DJI',
  'DMA',
  'DNK',
  'DOM',
  'DZA',
  'ECU',
  'EGY',
  'ERI',
  'ESH',
  'ESP',
  'EST',
  'ETH',
  'FIN',
  'FJI',
  'FLK',
  'FRA',
  'FRO',
  'FSM',
  'GAB',
  'GBR',
  'GEO',
  'GGY',
  'GHA',
  'GIB',
  'GIN',
  'GLP',
  'GMB',
  'GNB',
  'GNQ',
  'GRC',
  'GRD',
  'GRL',
  'GTM',
  'GUF',
  'GUM',
  'GUY',
  'HKG',
  'HMD',
  'HND',
  'HRV',
  'HTI',
  'HUN',
  'IDN',
  'IMN',
  'IND',
  'IOT',
  'IRL',
  'IRQ',
  'ISL',
  'ISR',
  'ITA',
  'JAM',
  'JEY',
  'JOR',
  'JPN',
  'KAZ',
  'KEN',
  'KGZ',
  'KHM',
  'KIR',
  'KNA',
  'KOR',
  'KWT',
  'LAO',
  'LBN',
  'LBR',
  'LBY',
  'LCA',
  'LIE',
  'LKA',
  'LSO',
  'LTU',
  'LUX',
  'LVA',
  'MAC',
  'MAR',
  'MCO',
  'MDA',
  'MDG',
  'MDV',
  'MEX',
  'MHL',
  'MKD',
  'MLI',
  'MLT',
  'MMR',
  'MNE',
  'MNG',
  'MNP',
  'MOZ',
  'MRT',
  'MSR',
  'MTQ',
  'MUS',
  'MWI',
  'MYS',
  'MYT',
  'NAM',
  'NCL',
  'NER',
  'NFK',
  'NGA',
  'NIC',
  'NIU',
  'NLD',
  'NOR',
  'NPL',
  'NRU',
  'NZL',
  'OMN',
  'PAK',
  'PAN',
  'PCN',
  'PER',
  'PHL',
  'PLW',
  'PNG',
  'POL',
  'PRI',
  'PRT',
  'PRY',
  'PYF',
  'QAT',
  'REU',
  'ROU',
  'RUS',
  'RWA',
  'SAU',
  'SEN',
  'SGP',
  'SGS',
  'SHN',
  'SJM',
  'SLB',
  'SLE',
  'SLV',
  'SMR',
  'SOM',
  'SPM',
  'SRB',
  'STP',
  'SUR',
  'SVK',
  'SVN',
  'SWE',
  'SWZ',
  'SYC',
  'TCA',
  'TCD',
  'TGO',
  'THA',
  'TJK',
  'TKL',
  'TKM',
  'TLS',
  'TON',
  'TTO',
  'TUN',
  'TUR',
  'TUV',
  'TWN',
  'TZA',
  'UGA',
  'UKR',
  'UMI',
  'URY',
  'USA',
  'UZB',
  'VAT',
  'VCT',
  'VEN',
  'VGB',
  'VIR',
  'VNM',
  'VUT',
  'WLF',
  'WSM',
  'YEM',
  'ZAF',
  'ZMB',
  'ZWE'
];
